<template>
  <div>
    <v-card>
      <!-- <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>GPM Settings</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar> -->

      <v-tabs>
        <v-tab dark>Notification</v-tab>

        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="12">
              <v-col cols="6">
                <v-checkbox
                  outlined
                  dense
                  v-model="NotifAlert"
                  label="Enable Email Notificaitons"
                ></v-checkbox>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  label="Email Address Separated By Semi Colon"
                  v-if="NotifAlert"
                  v-model="record.NotifEmail"
                ></v-text-field>
              </v-col>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>

      <v-row>
        <v-col cols="12">
          <v-btn
            color="accent"
            @click="sendData"
            :loading="$store.state.loader"
          >
            <v-icon left>mdi-content-save</v-icon>Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <!-- category quick add dialog -->
    <v-dialog v-model="openSyncedDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="modalToolbar" dark>
          <v-toolbar-title>Close Document</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="openSyncedDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="4">
                <v-autocomplete
                  outlined
                  dense
                  v-model="recordData.ObjType"
                  :items="numberingData"
                  item-text="DocumentName"
                  item-value="ObjectID"
                  label="Document"
                ></v-autocomplete>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  v-model="recordData.ExtRefDocNum"
                  label="Document Number"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <v-btn color="accent" @click="saveOpenDocuments">
                  <v-icon left>mdi-content-save</v-icon>Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->

    <snackbar ref="snackbar"></snackbar>
  </div>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    recordData: {},
    loader: false,
    numberingData: [],
    openDocuments: [],
    openSyncedDialog: false,
    NotifAlert: false,
    glTypes: [
      { name: "Item Group", value: "C" },
      { name: "Item Level", value: "L" },
      { name: "Warehouse", value: "W" },
    ],
    headers: [
      { text: "Document", value: "ObjType" },
      { text: "DocNum", value: "ExtRefDocNum" },
    ],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.NotifAlert = val.NotifAlert;
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.record.NotifAlert = this.NotifAlert;
      const details = this.record;
      // loader
      this.$store.commit("loader", true);
      this.$emit("data", details);
    },

    getData() {
      const self = this;

      this.$store
        .dispatch("get", `/doc_model?isDoc=1`)
        .then((res) => {
          self.numberingData = res;
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },

    saveOpenDocuments() {
      const data = this.recordData;
      const url = "/open-documents";
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.openSyncedDialog = false;
            this.getOpenDocuments();
            this.$store.commit("loader", false);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loader", false);
        });
    },

    getOpenDocuments() {
      const self = this;
      this.$store
        .dispatch("get", `/open-documents`)
        .then((res) => {
          self.openDocuments = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openToBeSysncedDialog() {
      this.openSyncedDialog = true;
    },
  },
  created() {},
};
</script>